import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Divider, Fade, IconButton, ListItemText, MenuItem, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import LayersRoundedIcon from "@mui/icons-material/LayersRounded";
import SaveAltRoundedIcon from "@mui/icons-material/SaveAltRounded";
import UploadIcon from "@mui/icons-material/Upload";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import db from "../../database/db";
import { createDeck, findAllDecksByUserId, findDeckById } from "../../services/data/deckDataService";
import CreateDeck from "../../components/dialogs/CreateDeck";
import UploadDeck from "../../components/dialogs/UploadDeck";
import ListDropdownMenu from "../../components/menus/ListDropdownMenu";
import PaperList from "../../components/list/PaperList";
import PaperListPlaceholder from "../../components/list/PaperListPlaceholder";
import PaperListLoading from "../../components/list/PaperListLoading";
import { setCurrentDeckId, setCurrentDeckDetails } from "../../redux/features/deck/deckSlice";
import { navigateTo } from "../../redux/features/screen/screenSlice";
import DropdownMainMenu from "../../components/menus/DropdownMainMenu";
import { setError } from "../../redux/features/error/errorSlice";

const MyDecksScreen = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [decks, setDecks] = useState([]);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [openCreateDeckDialog, setOpenCreateDeckDialog] = useState(false);
  const [openUploadDeckDialog, setOpenUploadDeckDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [deckCount, setDeckCount] = useState(null);
  const daysBeforeDeletion = useSelector((state) => state.syncing.daysBeforeDeletion);
  const lastSynced = useSelector((state) => state.syncing.lastSynced);
  const isSyncing = useSelector((state) => state.syncing.isSyncing);

  // Handle deck selection and navigate to that deck
  const handleDeckSelect = async (deckId) => {
    try {
      const deckDetails = await findDeckById(deckId);
      if (deckDetails) {
        dispatch(setCurrentDeckId(deckId));
        dispatch(setCurrentDeckDetails(deckDetails));
        dispatch(navigateTo("MyCards"));
      }
    } catch (error) {
      console.error("Error occurred while selecting deck:", error);
    }
  };

  // Function to open the dialog
  const handleOpenCreateDeckDialog = () => {
    setOpenCreateDeckDialog(true);
  };

  // Function to close the dialog
  const handleCloseCreateDeckDialog = () => {
    setOpenCreateDeckDialog(false);
  };

  // Toggle function for the Edit/Done button
  const toggleEdit = () => {
    setIsEditClicked((prev) => !prev);
  };

  const handleOpenExportDeck = () => {
    alert("Deck Exported!");
  };

  const handleOpenUploadDeckDialog = () => {
    setOpenUploadDeckDialog(true);
  };
  const handleCloseUploadDeckDialog = () => {
    setOpenUploadDeckDialog(false);
    refreshDecks();
  };

  const refreshDecks = async () => {
    try {
      const updatedDecks = await findAllDecksByUserId(userId);
      setDecks(updatedDecks);
      setDeckCount(updatedDecks.length);
    } catch (error) {
      console.error("Error occurred while refreshing decks:", error);
    }
  };

  // GET Decks from IndexedDB
  useEffect(() => {
    const getDecks = async () => {
      try {
        if (!userId) {
          return;
        }

        let timeoutId = setTimeout(() => setIsLoading(true), 500);

        const loadedDecks = await findAllDecksByUserId(userId);
        clearTimeout(timeoutId);
        setDecks(loadedDecks);
        setDeckCount(loadedDecks.length);
      } catch (error) {
        dispatch(setError("Couldn't load decks. Please try again"));
        console.error("ERROR: ", error);
      } finally {
        setIsLoading(false);
        setInitialLoad(false);
      }
    };

    getDecks();
  }, [userId, lastSynced]);

  // CREATE Decks
  const handleSubmitDeck = async ({ name, description, easeFactor, interval, easyRating, moderateRating, hardRating, veryHardRating }) => {
    try {
      if (!userId) {
        return;
      }

      const newDeckData = {
        name,
        description,
        createdBy: userId,
        easeFactor,
        interval,
        easyRating,
        moderateRating,
        hardRating,
        veryHardRating,
      };

      await createDeck(newDeckData, userId);
      const updatedDecks = await findAllDecksByUserId(userId);
      setDecks(updatedDecks);
      setDeckCount(updatedDecks.length);
    } catch (error) {
      dispatch(setError("An issue occurred while creating deck. Please try again"));
      console.error("ERROR: ", error);
    } finally {
      handleCloseCreateDeckDialog();
    }
  };

  // SOFT DELETE Decks
  const handleSoftDelete = async (event, deckId) => {
    try {
      event.stopPropagation();

      const deck = await db.decks.get(deckId);

      if (!deck) {
        return;
      }

      const now = new Date();
      const deletionDate = new Date(now);
      deletionDate.setDate(deletionDate.getDate() + daysBeforeDeletion);
      deck.deletedOn = deletionDate.toISOString();
      deck.modifiedOn = now.toISOString();

      await db.decks.put(deck);

      const updatedDecks = await findAllDecksByUserId(userId);
      setDecks(updatedDecks);
      setDeckCount(updatedDecks.length);
    } catch (error) {
      dispatch(setError("An issue occurred while deleting deck. Please try again"));
      console.error("ERROR: ", error);
    }
  };

  const getCharLimit = () => (isSmallScreen ? 25 : 55);

  const deckItemText = (deck) => {
    const charLimit = getCharLimit();
    return (
      <Typography variant="h2" sx={{ fontSize: { xs: "1rem", md: "1.1rem" } }}>
        {deck.name.length > charLimit ? deck.name.substring(0, charLimit) + "..." : deck.name}
      </Typography>
    );
  };

  //TODO: Add get cardCount and add at end of record
  const deckSecondaryText = (deck) => {
    // const cardCountStr = deck.cardCount !== undefined ? deck.cardCount.toString() : "0";

    return (
      <Typography variant="body2" color="text.secondary" sx={{ marginRight: 1, fontSize: { xs: ".8rem", sm: ".9rem" } }}>
        {/* {cardCountStr} */}
      </Typography>
    );
  };

  // Secondary action for PaperList
  const deckSecondaryAction = (deck) => (
    <Fade in={isEditClicked} timeout={300} unmountOnExit>
      <Stack direction="row" spacing={0.25}>
        <IconButton edge="end" aria-label="more" onClick={(e) => handleSoftDelete(e, deck.id)}>
          <DeleteOutlineRoundedIcon color="primary" />
        </IconButton>
      </Stack>
    </Fade>
  );

  const deckInfoText = (deck) => {
    // Placeholder for future logic to determine deck info, e.g., card count, study time
    const info = `Hello World`;

    // Conditional rendering based on `isEditClicked`
    if (!isEditClicked) {
      return (
        <Typography variant="body2" sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}>
          {info}
        </Typography>
      );
    } else {
      // When `isEditClicked` is true, return null or any other appropriate action or placeholder
      return null;
    }
  };

  return (
    <Stack data-testid="mydecks-screen" spacing={1.5}>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Box display="flex" alignItems="center">
          <IconButton
            color="primary"
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.1rem",
              },
              color: "text.primary",
              borderRadius: 1.5,
            }}
            onClick={handleOpenCreateDeckDialog}>
            <LibraryAddOutlinedIcon />
          </IconButton>
        </Box>
        <Stack direction="row" spacing={2} alignItems="center">
          {isSmallScreen && <DropdownMainMenu />}

          <Typography
            color="primary"
            onClick={toggleEdit}
            sx={{
              cursor: "pointer",
              color: "text.primary",
            }}>
            {isEditClicked ? "Done" : "Edit"}
          </Typography>
        </Stack>
      </Box>

      <Box>
        <ListDropdownMenu title="My Decks" color="text.primary">
          <MenuItem onClick={() => dispatch(navigateTo("DecksBin"))}>
            <ListItemText primary="Recently Deleted" />
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleOpenUploadDeckDialog}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <ListItemText primary="Upload Decks" />
              <UploadIcon />
            </Box>
          </MenuItem>
          <MenuItem disabled onClick={handleOpenExportDeck}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <ListItemText primary="Download Decks" />
              <SaveAltRoundedIcon />
            </Box>
          </MenuItem>
        </ListDropdownMenu>
      </Box>

      <Box>
        {isLoading || (isSyncing && deckCount === 0) ? (
          <PaperListLoading count={5} itemHeight="3rem" dividerInsetMargin="3rem" showPrimaryAction={true} showSecondaryText={false} />
        ) : !initialLoad && deckCount === 0 ? (
          <PaperListPlaceholder icon={LayersRoundedIcon} primaryText="No decks found" secondaryText="Create a new deck to get started." />
        ) : (
          <PaperList
            items={decks}
            onItemSelect={handleDeckSelect}
            isEditClicked={isEditClicked}
            ItemIcon={LayersRoundedIcon}
            secondaryAction={(deck) => (isEditClicked ? deckSecondaryAction(deck) : deckInfoText(deck))}
            itemText={deckItemText}
            itemSecondaryText={deckSecondaryText}
            showForwardIcon={true}
            itemHeight="3rem"
            dividerInsetMargin="3rem"
            // itemInfoText={(deck) => `${deck.cards.length}`} //TODO: Only cards not in recycle bin (deletedOn date === null)
          />
        )}
      </Box>

      <CreateDeck open={openCreateDeckDialog} onClose={handleCloseCreateDeckDialog} onSubmit={handleSubmitDeck} />
      <UploadDeck open={openUploadDeckDialog} onClose={handleCloseUploadDeckDialog} onCloseComplete={refreshDecks} />
    </Stack>
  );
};

export default MyDecksScreen;
