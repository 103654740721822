import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Box, Link, TextField, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from "react-redux";
import Papa from "papaparse";
import { findOrCreateDeck } from "../../services/data/deckDataService";
import { createCard } from "../../services/data/cardDataService";
import { useSync } from "../../hooks/sync";
import { setError } from "../../redux/features/error/errorSlice";

const UploadDeck = ({ open, onClose, onCloseComplete }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const sync = useSync();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onClose();
    if (onCloseComplete) onCloseComplete();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      setFile(file);
    } else {
      dispatch(setError("Please upload a .csv file."));
      setFile(null);
    }
  };

  const handleUploadSubmit = async (event) => {
    setIsLoading(true);
    try {
      Papa.parse(file, {
        header: true,
        complete: async (results) => {
          const decksMap = new Map();
          for (const row of results.data) {
            const { DeckName, Front, Back } = row;
            if (!DeckName || !DeckName.trim()) {
              continue;
            }

            if (!decksMap.has(DeckName)) {
              const deckId = await findOrCreateDeck(DeckName, userId);
              decksMap.set(DeckName, deckId);
            }

            const deckId = decksMap.get(DeckName);
            await createCard({ sideA: Front, sideB: Back }, deckId, userId);
          }
          await sync();
          setIsLoading(false);
          handleClose();
        },
        error: (error) => {
          dispatch(setError("Couldn't parse the file. Please make sure the file is a .csv and uses the provided template."));
          console.error("ERROR: ", error);
          setIsLoading(false);
        },
      });
    } catch (error) {
      dispatch(setError("Umm...something went wrong. Please try refreshing the page"));
      console.error("ERROR: ", error);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      data-testid="upload-deck-dialog"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          marginTop: { xs: -30, sm: 0 },
          display: "flex",
          flexDirection: "column",
          maxWidth: "none",
          width: { xs: "90%", sm: "70%", md: "40%" },
          borderRadius: 3,
        },
      }}>
      <DialogTitle>Upload Deck</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Please upload your decks using the structure outlined in our provided template to ensure smooth uploading of your data.
        </Typography>
        <Typography variant="body2">
          <Link href="/bfc_template.csv" download>
            Download the template file (bfc_template.csv)
          </Link>
        </Typography>
        <Box textAlign="center" my={2}>
          <Box my={4}>
            <CloudUploadIcon fontSize="large" color="primary" />
            <Typography variant="subtitle1" gutterBottom>
              Choose a .csv file to upload
            </Typography>
          </Box>
          <TextField
            data-testid="upload-file-input"
            type="file"
            onChange={handleFileChange}
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              accept: ".csv",
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button onClick={handleUploadSubmit} variant="contained" color="primary" fullWidth disabled={!file || isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Upload"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDeck;
