import { findFolderByName, createFolder, findFileByName, findFileById, createRemoteDb, updateFileById } from "../drive/googleDriveService";
import db from "../../database/db";
import v1_template from "../../database/v1_template.json";
import { updateSyncing, updateLastSynced } from "../../redux/features/syncing/syncingSlice";
import { isTokenValid } from "../../utilities/validateToken";
import { migrateDecks } from "./deckDataService";

let isSettingUpFolders = false;

export const syncData = async (accessToken, tokenExpiration, dispatch, updateGoogleParentId, updateGoogleDataId, updateGoogleDataFileId, lastSyncedTime, daysBeforeDeletion, userId) => {
  if (!isTokenValid(tokenExpiration)) {
    return;
  }

  if (isSettingUpFolders) {
    return;
  }

  isSettingUpFolders = true;

  const newSyncedTime = new Date().toISOString();

  const findOrCreateFolder = async (folderName, parentFolderId = null) => {
    try {
      let folder = await findFolderByName(folderName, accessToken, parentFolderId);
      if (!folder) {
        folder = await createFolder(folderName, accessToken, parentFolderId);
      }
      return folder.id;
    } catch (error) {
      console.error("Error in findOrCreateFolder: ", error);
      return null; // Ensure function returns null if there's an error
    }
  };

  const setupFolders = async () => {
    try {
      const basicAppsFolderId = await findOrCreateFolder("Mnemonic");
      const appFolderId = await findOrCreateFolder("FlashCards", basicAppsFolderId);
      dispatch(updateGoogleParentId(appFolderId));
      const dataFolderId = await findOrCreateFolder("Data", appFolderId);
      dispatch(updateGoogleDataId(dataFolderId));

      let dataFileId = null;
      const dataFile = await findFileByName("data", accessToken, dataFolderId);
      if (!dataFile) {
        const placeholderData = JSON.stringify(v1_template);
        const newDataFile = await createRemoteDb("data", accessToken, dataFolderId, new Blob([placeholderData], { type: "text/json" }));
        dataFileId = newDataFile.id;
      } else {
        dataFileId = dataFile.id;
      }
      dispatch(updateGoogleDataFileId(dataFileId));
      return dataFileId;
    } catch (error) {
      console.error("Error in setupFolders: ", error);
      return null; // Ensure function returns null if there's an error
    }
  };

  try {
    await migrateDecks();
  } catch (error) {
    console.error("Error in migrateDecks: ", error);
  }

  const pullLocalDb = async () => {
    try {
      if (typeof userId !== "string" && typeof userId !== "number") {
        console.error("Invalid userId for database query:", userId);
        return null; // Early exit if userId is not valid
      }
      const tables = db.tables.map((table) => table.name);
      const currentDate = new Date();
      const lastSyncDate = new Date(lastSyncedTime);
      const diffTime = Math.abs(currentDate - lastSyncDate);
      const expiryDays = daysBeforeDeletion;
      const expiryDurationMs = expiryDays * 24 * 60 * 60 * 1000;

      if (diffTime > expiryDurationMs) {
        for (const tableName of tables) {
          await db.table(tableName).clear();
        }
        return null;
      } else {
        const data = await Promise.all(
          tables.map(async (tableName) => {
            const rows = await db.table(tableName).where({ createdBy: userId }).toArray();
            const schema =
              db.table(tableName).schema.primKey.src +
              "," +
              db
                .table(tableName)
                .schema.indexes.map((idx) => idx.src)
                .join(",");
            return { tableName, inbound: true, rows, schema };
          })
        );

        const exportData = {
          formatName: "dexie",
          formatVersion: 1,
          data: {
            databaseName: db.name,
            databaseVersion: db.verno,
            tables: data.map(({ tableName, schema, rows }) => ({
              name: tableName,
              schema,
              rowCount: rows.length,
            })),
            data: data.map(({ tableName, inbound, rows }) => ({
              tableName,
              inbound,
              rows,
            })),
          },
        };
        return exportData;
      }
    } catch (error) {
      console.error("Error in pullLocalDb: ", error);
      return null;
    }
  };

  const pullRemoteDb = async (dataFileId) => {
    try {
      const remoteFile = await findFileById(dataFileId, accessToken);
      const fileContentUrl = `https://www.googleapis.com/drive/v3/files/${remoteFile.id}?alt=media`;
      const blobResponse = await fetch(fileContentUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!blobResponse.ok) {
        console.error(`Error fetching blob: ${blobResponse.statusText}`);
      }
      const remoteBlob = await blobResponse.blob();
      const blobText = await remoteBlob.text();
      const blobJson = JSON.parse(blobText);
      return blobJson;
    } catch (error) {
      console.error("Error in pullRemoteDb: ", error);
      return null;
    }
  };

  const mergeLocalAndRemoteDB = async (localBlob, remoteBlob) => {
    try {
      if (!remoteBlob) {
        return null;
      }
      let mergedData;
      const mergeRows = (localRows, remoteRows) => {
        const merged = {};
        localRows.forEach((row) => {
          merged[row.id] = { ...row };
        });
        remoteRows.forEach((row) => {
          if (!merged[row.id] || new Date(row.modifiedOn) > new Date(merged[row.id].modifiedOn)) {
            merged[row.id] = { ...row };
          }
        });
        return Object.values(merged);
      };

      if (!localBlob || !localBlob.data) {
        mergedData = remoteBlob;
      } else {
        mergedData = {
          formatName: localBlob.formatName,
          formatVersion: localBlob.formatVersion,
          data: {
            databaseName: localBlob.data.databaseName,
            databaseVersion: localBlob.data.databaseVersion,
            tables: localBlob.data.tables,
            data: localBlob.data.data.map((localTable) => {
              const remoteTableData = remoteBlob.data.data.find((t) => t.tableName === localTable.tableName);
              if (!remoteTableData) {
                return localTable;
              }
              return {
                ...localTable,
                rows: mergeRows(localTable.rows, remoteTableData.rows),
              };
            }),
          },
        };
      }
      return mergedData;
    } catch (error) {
      console.error("Error in mergeLocalAndRemoteDB: ", error);
      return null;
    }
  };

  const pushLocalDb = async (mergedData) => {
    try {
      for (const mergedTable of mergedData.data.data) {
        if (mergedTable.rows.length === 0) {
          continue;
        }
        const rowsToUpdate = [];
        const idsToDelete = [];
        for (const row of mergedTable.rows) {
          if (row.deletedOn && new Date(row.deletedOn) < new Date()) {
            idsToDelete.push(row.id);
          } else {
            const cleanedRow = { ...row };
            delete cleanedRow.$; // Remove the '$' property
            delete cleanedRow.$types; // Remove the '$types' property
            rowsToUpdate.push(cleanedRow);
          }
        }
        if (rowsToUpdate.length > 0) {
          await db.table(mergedTable.tableName).bulkPut(rowsToUpdate);
        }
        if (idsToDelete.length > 0) {
          await db.table(mergedTable.tableName).bulkDelete(idsToDelete);
        }
      }
    } catch (error) {
      console.error("Error in pushLocalDb: ", error);
    }
  };

  const pushRemoteDb = async (mergedData, dataFileId) => {
    try {
      const dataForRemote = {
        ...mergedData,
        data: {
          ...mergedData.data,
          data: mergedData.data.data.map((table) => ({
            ...table,
            rows: table.rows.filter((row) => !(row.deletedOn && new Date(row.deletedOn) < new Date())),
          })),
        },
      };
      const mergedDataBlob = new Blob([JSON.stringify(dataForRemote)], { type: "application/json" });
      await updateFileById(dataFileId, mergedDataBlob, accessToken);
    } catch (error) {
      console.error("Error in pushRemoteDb: ", error);
    }
  };

  const initialize = async () => {
    if (!accessToken) return;

    dispatch(updateSyncing(true));

    try {
      const dataFileId = await setupFolders();
      const localBlob = await pullLocalDb();
      const remoteBlob = await pullRemoteDb(dataFileId);
      const mergedData = await mergeLocalAndRemoteDB(localBlob, remoteBlob);
      if (mergedData) {
        await pushLocalDb(mergedData);
        await pushRemoteDb(mergedData, dataFileId);
      }
    } catch (error) {
      console.error("Error in initialize: ", error);
    } finally {
      isSettingUpFolders = false;
      dispatch(updateSyncing(false));
      dispatch(updateLastSynced(newSyncedTime));
    }
  };

  initialize();
};
