import React from "react";
import { Box, Divider, MenuItem, Stack, ListItemText } from "@mui/material";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import ListDropdownMenu from "../menus/ListDropdownMenu";

const CardsScreenMenu = ({ deck, handleUpdateDeck, navigateTo, dispatch }) => {
  return (
    <Stack data-testid="cards-screen-menu" direction="row" justifyContent="space-between" width="100%">
      <ListDropdownMenu title={deck ? deck.name : "..."} color="text.primary">
        <MenuItem onClick={() => dispatch(navigateTo("CardsBin"))}>
          <ListItemText primary="Recently Deleted" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleUpdateDeck}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <ListItemText primary="Settings" />
            <SettingsRoundedIcon />
          </Box>
        </MenuItem>
      </ListDropdownMenu>
    </Stack>
  );
};

export default CardsScreenMenu;
