/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import PolicyLinks from "../../components/common/PolicyLinks";
import Board from "../../components/layout/Board";
import { useSelector, useDispatch } from "react-redux";
import { updateGoogleParentId, updateGoogleDataId, updateGoogleDataFileId } from "../../redux/features/drive/cloudStorageSlice";
import { syncData } from "../../services/data/syncServices";
import { useAuth } from "../../hooks/auth";

function Content({ children }) {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.userId);
  const accessToken = useSelector((state) => state.auth.googleAccessToken);
  const tokenExpiration = useSelector((state) => state.auth.tokenExpiration);
  const lastSyncedTime = useSelector((state) => state.syncing.lastSynced);
  const daysBeforeDeletion = useSelector((state) => state.syncing.daysBeforeDeletion);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    try {
      if (isAuthenticated && accessToken) {
        syncData(accessToken, tokenExpiration, dispatch, updateGoogleParentId, updateGoogleDataId, updateGoogleDataFileId, lastSyncedTime, daysBeforeDeletion, userId);
      }
    } catch (error) {
      console.error("Error occurred during initial synchronization:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, tokenExpiration, dispatch, isAuthenticated]);

  // Setup periodic sync
  useEffect(() => {
    try {
      // Define the sync interval in minutes
      const syncIntervalMinutes = 10;

      // Set up the interval for auto-sync
      const syncInterval = setInterval(() => {
        if (isAuthenticated && accessToken) {
          syncData(accessToken, tokenExpiration, dispatch, updateGoogleParentId, updateGoogleDataId, updateGoogleDataFileId, lastSyncedTime, daysBeforeDeletion, userId);
        }
      }, syncIntervalMinutes * 60 * 1000);

      return () => clearInterval(syncInterval);
    } catch (error) {
      console.error("Error occurred during periodic synchronization setup:", error);
    }
  }, [accessToken, tokenExpiration, dispatch, isAuthenticated]);

  return (
    <>
      <Box
        data-testid="content-layout"
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="100vh"
        sx={{
          mt: 0,
          backgroundColor: {
            xs: "#fcdbb9",
            md: "inherit",
          },
        }}>
        <Stack spacing={{ xs: 2, sm: 2, md: 1 }} alignItems="center" width="100%" height="100%">
          <Box sx={{ display: { xs: "none", md: "block" } }}>{/* <BrandTitle /> */}</Box>

          <Board>
            <Stack
              spacing={2.2}
              sx={{
                px: 2,
                pt: 1,
                pb: 2,
                mb: {
                  xs: 0,
                  sm: 3,
                },
              }}>
              {children}
            </Stack>
          </Board>

          <Box sx={{ pb: 1, display: { xs: "none", sm: "block" } }}>
            <PolicyLinks />
          </Box>
        </Stack>
      </Box>
    </>
  );
}

export default Content;
