import React from "react";
import { Stack, Typography } from "@mui/material";
import StyleRoundedIcon from "@mui/icons-material/StyleRounded";

const BrandTitle = () => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-evenly" spacing={2} data-testid="brand-title" sx={{ mt: 4 }}>
      <StyleRoundedIcon sx={{ fontSize: 32, color: "primary.main" }} />
      <Typography
        variant="h6"
        component="h1"
        sx={{
          fontSize: "1.5rem",
          fontWeight: "medium",
          color: "text.primary",
        }}>
        {/* Mnemonic */}
        Basic FlashCards
      </Typography>
    </Stack>
  );
};

export default BrandTitle;
