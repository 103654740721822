import db from "../../database/db";
import { v4 as uuidv4 } from "uuid";

export const createSession = async (deckId, shuffledCards, userId) => {
  try {
    const now = new Date().toISOString();

    const sessionCards = shuffledCards.map((card) => ({
      ...card,
      sessionCardId: uuidv4(),
      isReviewed: false,
      isDuplicate: false,
      isReset: false,
    }));

    const session = {
      id: uuidv4(),
      deckId: deckId,
      startedOn: now,
      endedOn: null,
      completedOn: null,
      cards: sessionCards,
      createdBy: userId,
    };

    const sessionId = await db.sessions.add(session);
    return sessionId;
  } catch (error) {
    console.error("Error creating session:", error);
    return null;
  }
};

export const findSessionById = async (sessionId) => {
  try {
    const session = await db.sessions.get(sessionId);
    if (!session) {
      console.error("Session not found:", sessionId);
      return null;
    }
    return session;
  } catch (error) {
    console.error("Error retrieving session from IndexedDB:", error);
    return null;
  }
};

export const updateSessionCard = async (sessionId, cardUpdates) => {
  try {
    const session = await db.sessions.get(sessionId);
    if (!session) {
      console.error("Session not found:", sessionId);
      return null;
    }

    const updatedCards = session.cards.map((card) => {
      const updateForCard = cardUpdates.find((update) => update.cardId === card.id);
      if (updateForCard) {
        return { ...card, ...updateForCard };
      }
      return card;
    });

    await db.sessions.update(sessionId, { cards: updatedCards });
    return updatedCards;
  } catch (error) {
    console.error("Error updating session cards:", error);
    return null;
  }
};

export const resetSessionCard = async (sessionId, currentCard) => {
  try {
    const session = await db.sessions.get(sessionId);
    if (!session) {
      console.error("Session not found:", sessionId);
      return;
    }

    const card = await db.cards.get(currentCard.id);
    if (!card) {
      console.error("Card not found:", currentCard.id);
      return;
    }

    const updatedCards = session.cards.map((sc) => {
      if (sc.sessionCardId === currentCard.sessionCardId) {
        return { ...sc, ...card, reviewed: false, reset: true, isDuplicate: currentCard.isDuplicate || false };
      }
      return sc;
    });

    if (!currentCard.isDuplicate) {
      const duplicateCardIndex = updatedCards.findIndex((sc) => sc.id === currentCard.id && sc.isDuplicate === true);
      if (duplicateCardIndex !== -1) {
        updatedCards.splice(duplicateCardIndex, 1);
      }
    }

    await db.sessions.update(sessionId, { cards: updatedCards });
  } catch (error) {
    console.error("Error resetting session card:", error);
  }
};

export const appendCardToSession = async (sessionId, cardToAppend) => {
  try {
    const session = await db.sessions.get(sessionId);
    if (!session) {
      console.error("Session not found:", sessionId);
      return null;
    }

    const appendedCard = { ...cardToAppend, sessionCardId: uuidv4() };
    session.cards.push(appendedCard);

    await db.sessions.update(sessionId, { cards: session.cards });
    return appendedCard;
  } catch (error) {
    console.error("Error appending card to session:", error);
    return null;
  }
};

export const processSessionData = async (sessionId) => {
  try {
    const session = await db.sessions.get({ id: sessionId });
    if (!session) {
      console.error("Session not found:", sessionId);
      return null;
    }

    if (!session.endedOn) {
      await db.sessions.update(sessionId, { endedOn: new Date().toISOString() });
    }

    // Placeholder for processing logic
    // Update the session or cards as needed here

    return await db.sessions.get({ id: sessionId });
  } catch (error) {
    console.error("Error processing session data:", error);
    return null;
  }
};

export const endSession = async (sessionId) => {
  try {
    await db.sessions.update(sessionId, { endedOn: new Date().toISOString() });
    return await db.sessions.get({ id: sessionId });
  } catch (error) {
    console.error("Error ending session:", error);
    return null;
  }
};
