// src\utilities\quillConfig.js

export const modules = {
  toolbar: [
    [{ font: [] }, "bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [({ script: "sub" }, { script: "super" })], // superscript/subscript
    [{ color: [] }, { background: [] }, "clean"], // dropdown with defaults from theme

    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { direction: "rtl" }], // outdent/indent
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown

    ["image", "link", "code-block"], // link and image
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "background",
  "align",
  "clean",
  "code-block",
  "script",
  "direction",
];
