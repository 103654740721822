import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Snackbar, Grow, useTheme } from "@mui/material";
import { clearError } from "../../redux/features/error/errorSlice";

export function ErrorAlert() {
  const theme = useTheme();
  const { message, showError } = useSelector((state) => state.error);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(clearError());
  };

  return (
    <Snackbar
      open={showError}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionComponent={(props) => <Grow {...props} direction="down" />}>
      <Alert
        onClose={handleClose}
        severity="error"
        sx={{
          width: "100%",
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
          "& .MuiSvgIcon-root": { color: theme.palette.error.contrastText },
          boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
        }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default ErrorAlert;
