import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { styled } from "@mui/material/styles";
import { useSync } from "../../hooks/sync";
import AppDialog from "../common/AppDialog";
import IOSSwitch from "../common/IOSSwitch";
import IOSSlider from "../common/IOSSlider";

const CreateDeck = ({ open, onClose, onSubmit, initialName = "", initialDescription = "" }) => {
  const [name, setName] = useState(initialName);
  const [description, setDescription] = useState(initialDescription);
  const [tabValue, setTabValue] = useState(0);
  const [easeFactor, setEaseFactor] = useState(2.5);
  const [interval, setInterval] = useState("1");
  const [resetDeck, setResetDeck] = useState(true);
  const [selectedOption, setSelectedOption] = useState("standard");
  const [isLoading, setIsLoading] = useState(true);
  const [easyRating, setEasyRating] = useState(0.2);
  const [moderateRating, setModerateRating] = useState(0.1);
  const [hardRating, setHardRating] = useState(-0.2);
  const [veryHardRating, setVeryHardRating] = useState(-0.3);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const studyModeOptions = {
    cram: { easeFactor: 1.3, interval: 0.01 },
    standard: { easeFactor: 2.5, interval: 1 },
    casual: { easeFactor: 3.5, interval: 7 },
    custom: { easeFactor: easeFactor, interval: interval },
  };

  const sync = useSync();

  useEffect(() => {
    if (open) {
      setName(initialName);
      setDescription(initialDescription);
      setIsLoading(true);
      setTabValue(0);
      setResetDeck(true);
      setIsLoading(false);
      setInterval("1");
      setTabValue(0);
      setEaseFactor(2.5);
      setSelectedOption("standard");
    }
  }, [open, initialName, initialDescription]);

  const handleChange = (event) => {
    try {
      setInterval(event.target.value);
    } catch (error) {
      console.error("Error occurred while handling interval change:", error);
    }
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(1),
      border: 0,
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
  }));

  const handleSliderChange = (event, newValue) => {
    try {
      setEaseFactor(newValue);
    } catch (error) {
      console.error("Error occurred while handling slider change:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    try {
      setTabValue(newValue);
    } catch (error) {
      console.error("Error occurred while handling tab change:", error);
    }
  };

  const handleStudyModeChange = (event) => {
    try {
      const newStudyMode = event.target.value;
      setSelectedOption(newStudyMode);

      // Automatically set values for non-custom options
      if (newStudyMode !== "custom") {
        const { easeFactor, interval } = studyModeOptions[newStudyMode];
        setEaseFactor(easeFactor);
        setInterval(interval.toString());
      }
    } catch (error) {
      console.error("Error occurred while handling study mode change:", error);
    }
  };

  const handleClose = () => {
    try {
      onClose();
    } catch (error) {
      console.error("Error occurred while handling close:", error);
    }
  };

  const validateDecimalInput = (value, setterFunction) => {
    try {
      if (/^-?\d*\.?\d*$/.test(value)) {
        setterFunction(value);
      }
    } catch (error) {
      console.error("Error occurred while validating decimal input:", error);
    }
  };

  // CreateDeck.jsx
  const handleFormSubmit = async (event) => {
    try {
      event.preventDefault();

      const submissionData = {
        name,
        description,
        easeFactor: parseFloat(easeFactor),
        interval: parseFloat(interval),
        easyRating: parseFloat(easyRating),
        moderateRating: parseFloat(moderateRating),
        hardRating: parseFloat(hardRating),
        veryHardRating: parseFloat(veryHardRating),
      };

      onSubmit(submissionData);
      await sync();
    } catch (error) {
      console.error("Error occurred while handling form submit:", error);
    }
  };

  return (
    <AppDialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        Create Deck
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500], //TODO: Use brand colors
          }}>
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          borderRadius: ".5rem",
          my: 1,
          mx: "auto",
        }}>
        <StyledToggleButtonGroup size="small" exclusive value={tabValue} onChange={handleTabChange} aria-label="deck details" sx={{ display: "flex", justifyContent: "center" }}>
          <ToggleButton value={0} aria-label="details">
            <AssignmentRoundedIcon sx={{ mr: 1 }} />
            General
          </ToggleButton>
          <ToggleButton value={1} aria-label="advanced-options">
            <TuneRoundedIcon sx={{ mr: 1 }} />
            Advanced
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Box>

      {isLoading ? (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      ) : (
        <form onSubmit={handleFormSubmit}>
          <DialogContent
            sx={{
              padding: "10px 24px 10px 24px",
            }}>
            {tabValue === 0 && (
              <>
                <TextField
                  autoFocus
                  margin="dense"
                  id="deckName"
                  name="deckName"
                  label="Deck Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
                <TextField
                  margin="dense"
                  id="deckDescription"
                  name="deckDescription"
                  label="Description"
                  multiline
                  rows={5}
                  variant="outlined"
                  fullWidth
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </>
            )}
            {tabValue === 1 && (
              <Stack spacing={1}>
                <Box>
                  <FormControlLabel disabled control={<IOSSwitch checked={resetDeck} onChange={(e) => setResetDeck(e.target.checked)} />} label="Reset Deck" />
                </Box>

                <Stack direction="row" spacing={2} alignItems="flex-start" justifyContent="space-evenly">
                  <Box
                    sx={{
                      flexGrow: 1,
                      p: 2,
                      width: "50%",
                    }}>
                    <Typography variant="h6" color="text.primary" gutterBottom>
                      Study Mode
                    </Typography>

                    <Stack spacing={1} alignItems="flex-start" justifyContent="space-evenly">
                      <FormControl size="small" fullWidth>
                        <Select disabled={!resetDeck} value={selectedOption} onChange={handleStudyModeChange} displayEmpty inputProps={{ "aria-label": "Without label" }}>
                          <MenuItem value="cram">Cram</MenuItem>
                          <MenuItem value="standard">Standard</MenuItem>
                          <MenuItem value="casual">Casual</MenuItem>
                          <MenuItem value="custom">Custom</MenuItem>
                          {/* <MenuItem disabled value="uniformInterval">
                            Fixed
                          </MenuItem> */}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Box>
                  <Divider orientation="vertical" flexItem sx={{ mx: 2, height: "auto" }} />
                  <Box
                    sx={{
                      flexGrow: 1,
                      p: 2,
                      width: "50%",
                    }}>
                    <Typography variant="h6" color="text.primary" gutterBottom>
                      Spaced Repetition Options
                    </Typography>
                    <Stack spacing={1} alignItems="flex-start" justifyContent="space-evenly">
                      <Box sx={{ width: "100%" }}>
                        <Typography id="ease-factor-slider" gutterBottom>
                          Ease Factor:
                        </Typography>
                        <IOSSlider
                          disabled={selectedOption !== "custom"}
                          aria-label="ios slider"
                          value={easeFactor}
                          valueLabelDisplay="on"
                          onChange={handleSliderChange}
                          min={1.3}
                          max={3.5}
                          step={0.1}
                          sx={{ my: 2 }}
                        />
                        <Typography id="custom-select-label" gutterBottom sx={{ mb: 2 }}>
                          Starting Review Interval:
                        </Typography>
                        <FormControl size="small" fullWidth>
                          <Select sx={{ mb: 2 }} disabled={selectedOption !== "custom"} labelId="custom-select-label" id="custom-select" value={interval} onChange={handleChange}>
                            <MenuItem value={0.000694}>1 minute</MenuItem>
                            <MenuItem value={0.01}>15 minutes</MenuItem>
                            <MenuItem value={0.04}>1 hour</MenuItem>
                            <MenuItem value={0.17}>4 hours</MenuItem>
                            <MenuItem value={1}>1 day</MenuItem>
                            <MenuItem value={2}>2 days</MenuItem>
                            <MenuItem value={3}>3 days</MenuItem>
                            <MenuItem value={7}>1 week</MenuItem>
                            <MenuItem value={14}>2 weeks</MenuItem>
                            <MenuItem value={30}>1 month</MenuItem>
                            {/* Add more intervals as needed */}
                          </Select>
                        </FormControl>
                      </Box>

                      {selectedOption === "uniformInterval" && (
                        <Box sx={{ width: "100%" }}>
                          <Typography id="uniformInterval-select-label" gutterBottom sx={{ mb: 2 }}>
                            Fixed Review Interval:
                          </Typography>
                          <FormControl size="small">
                            <Select labelId="uniformInterval-label" id="uniformInterval-select" value={interval} onChange={handleChange}>
                              <MenuItem value={0.01}>15 minutes</MenuItem>
                              <MenuItem value={0.04}>1 hour</MenuItem>
                              <MenuItem value={0.17}>4 hours</MenuItem>
                              <MenuItem value={1}>1 day</MenuItem>
                              <MenuItem value={2}>2 days</MenuItem>
                              <MenuItem value={3}>3 days</MenuItem>
                              <MenuItem value={7}>1 week</MenuItem>
                              <MenuItem value={14}>2 weeks</MenuItem>
                              <MenuItem value={30}>1 month</MenuItem>
                              {/* Add more intervals as needed */}
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
            )}
          </DialogContent>

          <DialogActions
            sx={{
              padding: "10px 24px 20px 24px",
            }}>
            <Button variant="outlined" onClick={onClose} fullWidth>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Save
            </Button>
          </DialogActions>
        </form>
      )}
    </AppDialog>
  );
};

export default CreateDeck;
