import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { goBack, selectNavigationHistory } from "../../redux/features/screen/screenSlice";
import { setError } from "../../redux/features/error/errorSlice";

const BreadcrumbsNav = () => {
  const dispatch = useDispatch();
  const history = useSelector(selectNavigationHistory);

  const breadcrumbStyle = {
    fontSize: "1rem",
    cursor: "pointer",
    color: "text.secondary",
  };

  const lastBreadcrumbStyle = {
    ...breadcrumbStyle,
    fontWeight: "600",
    color: "text.primary",
  };

  const handleBack = () => {
    try {
      dispatch(goBack());
    } catch (error) {
      dispatch(setError("Umm...something went wrong. Please try refreshing the page"));
      console.error("ERROR: ", error);
    }
  };

  if (history.length === 0) {
    return null;
  }

  const getDisplayName = (screenId) => {
    try {
      const screenToDisplayName = {
        Home: "Home",
        MyDecks: "My Decks",
        DecksBin: "Recently Deleted",
        MyCards: "Cards",
        CardsBin: "Recently Deleted",
      };

      return screenToDisplayName[screenId] || screenId;
    } catch (error) {
      dispatch(setError("Couldn't find the display name. Please try refreshing the page"));
      console.error("ERROR: ", error);
      return screenId;
    }
  };

  const lastScreenId = history[history.length - 1];
  const lastScreenName = getDisplayName(lastScreenId);

  return (
    <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={handleBack}>
      <IconButton aria-label="Go back" sx={{ marginRight: "4px", ...breadcrumbStyle }} disableRipple>
        <ArrowBackIosNewRoundedIcon sx={{ color: breadcrumbStyle.color, fontSize: 20 }} />
      </IconButton>
      <Typography variant="h6" sx={lastBreadcrumbStyle} noWrap>
        {lastScreenName}
      </Typography>
    </Box>
  );
};

export default BreadcrumbsNav;
